import React, { Component } from 'react';
import { Route } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import _load from './load.gif';
require('bootstrap')

class Queues extends Component {
    constructor() {
        super();
       
        this.state = {
            data: [],
            searchedData: [],
            search: ''
        };
    }
    componentDidMount = () => {
        fetch('Admin/GetQueues').then(x => x.json()).then(x => this.setState({ data: x, searchedData: x }));
    }

    searchE = (e) => {
        this.setState({ search: e.target.value });
        if (e.target.value.lenght === 0) {
            this.setState({ searchedData: this.state.data });
        } else {
            var re = new RegExp(e.target.value, 'i');
            var arr = [];
            this.state.data.forEach(function (item) {
                if (item.userId !== undefined && item.userId !== null) {
                    if (item.userId.toString().match(re)) {
                        arr.push(item);
                    }
                }
               
            });
            this.setState({ searchedData: arr });
        }
        
    }
    getCertName = (id) => {
        var certs = [
            ["9", "Новус"],
            ["10", "Комфі"],
            ["11", "Фокстрот"],
            ["19", "Епіцентр"],
            ["44", "Эльдорадо"],
            ["79", "Бомонд"],
            ["80", "Сільпо"],
            ["187", "Алло"],
            ["245", "ОККО"],
            ["258", "Планета кіно"],
            ["265", "Пандора"],
            ["281", "Якабу"],
            ["288", "Аптека"],
            ["300", "Андер Армор"],
            ["345", "Концерт Юа"],
            ["346", "Локаль"],
            ["396", "Золотий Вік"],
            ["459", "Гастрофемілі"],
            ["301", "МД Фешн"],
            ["490", "Мультиплекс"],
            ["140", "Вайнтайм"],
            ["10001", "Ля Фамілія"],
            ["10002", "Флорум"],
            ["10003", "Спортлайф"],
            ["10004", "Репортер"],
            ["10005", "Артист"],
            ["10006", "Коаст"],
            ["10007", "Будинок Іграшок"],
            ["10008", "Унмоменто"],
            ["10009", "Такси"],
            ["10010", "Планета кино 100 x5"],
            ["10017", " Novus 100"],
            ["10020", "Watson 100"],
            ["10025", "Eva 50"],
            ["10026", "Eva 100"],
            ["10016", "Auchan 200"],
            ["10031", "Goodwine ручные"],
            ["10032", "Bolt Україна 5*100 грн"],
            ["10034", "Bolt Польща 3*5Євро"],
            ["10035", "Bolt Німеччина 3*5Євро"],
            ["10036", "Bolt Чехія 3*5Євро"],
            ["10033", "Bolt Food Україна 5*100 грн"],
            ["10037", "Bolt Food Польща 3*5Євро"],
            ["10038", "Bolt Food Німеччина 3*5Євро"],
            ["10039", "Bolt Food Чехія 3*5Євро"],
            ["10346", "Локаль 1000"],
        ];
        for (var i = 0; i < certs.length; i++) {
            if (parseInt(certs[i][0]) === id) {
                return certs[i][1];
            }
        }
    }
   
    render() {
        return (
            <div className="">
                <input value={this.state.search} onInput={this.searchE} type="search" />
                <table className="table">
                    <tr>
                        <th>Id</th>
                        <th>Время</th>
                        <th>Подтвержден</th>
                        <th>Закончен</th>
                        <th>Сертификат</th>
                        <th>UserId</th>
                    </tr>
                    {this.state.searchedData.map(x =>
                        <tr>
                            <td>{x.id}</td>
                            <td>{x.time}</td>
                            <td>{x.confirmed ? "Да" : "Нет"}</td>
                            <td>{x.ended ? "Да" : "Нет"}</td>
                            <td>{this.getCertName(x.type)}</td>
                            <td>{x.userId == undefined || null ? -1 : x.userId}</td>
                        </tr>
                    )}
                </table>
            </div>
        );
    }

}
export default Queues;


