import React, { Component } from 'react'
import { Button } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';

export default class EuropeanCerts extends Component {
    constructor() {
        super()
        this.state = {
            certs: [
                
            ],
            modal: false,
            selectedCert: -1,
            newReserv: '',
            polandTableShow: false,
            germanyTableShow: false,
            spainTableShow: false,
        }
    }

    componentDidMount = () => {
        this.fetchReservs();
    }


    fetchReservs = () => {
        fetch('Admin/GetEuroReservs').then(x => x.json()).then(x => this.setState({ certs: x }))
    }

    getCertName = (id) => {
        var certs = [
            ["30001", "Kaufland  20zl"],
            ["30002", "Kaufland  50zl"],
            ["30003", "Kaufland  100zl"],
            ["30004", "Carrefour 20zl"],
            ["30005", "Carrefour 50zl"],
            ["30006", "Carrefour 100zl"],
            ["30007", "Amazon 20zl"],
            ["30008", "Amazon 50zl"],
            ["30009", "Amazon 100zl"],
            ["30010", "Dekatlon 20zl"],
            ["30011", "Dekatlon 50zl"],
            ["30012", "Dekatlon 100zl"],
            ["30013", "Media Expert 50zl"],
            ["30014", "Media Expert 100zl"],
            ["30015", "Biedronka 20zl"],
            ["30016", "Biedronka 50zl"],
            ["30017", "Biedronka 100zl"],
            ["30018", "Allegro 20zl"],
            ["30019", "Allegro 50zl"],
            ["30020", "Allegro 100zl"],
            ["40001", "Loccitane 50eur"],
            ["40002", "Rituals 5eur"],
            ["40003", "Rituals 10eur"],
            ["40004", "Rituals 50eur"],
            ["40005", "Zalando 5eur"],
            ["40006", "Zalando 10eur"],
            ["40007", "Zalando 50eur"],
            ["40008", "Primark 5eur"],
            ["40009", "Primark 10eur"],
            ["40010", "Primark 50eur"],
            ["40011", "Adidas 50eur"],
            ["40012", "Airbnb 50eur"],
            ["40013", "Airbnb 100eur"],
            ["50001", "TheFork 5eur"],
            ["50002", "TheFork 10eur"],
            ["50003", "TheFork 50eur"],
            ["50004", "Glovo 50eur"],
            ["50005", "Rituals 5eur"],
            ["50006", "Rituals 10eur"],
            ["50007", "Rituals 50eur"],
            ["50008", "Zalando 5eur"],
            ["50009", "Zalando 10eur"],
            ["50010", "Zalando 50eur"],
            ["50011", "Primark 5eur"],
            ["50012", "Primark 10eur"],
            ["50013", "Primark 50eur"],
            ["50014", "Adidas 50eur"],
            ["50015", "Airbnb 50eur"],
            ["50016", "Airbnb 100eur"],
            ["50017", "Wonderbox (Oenology Experience) 10eur"],
            ["50018", "Wonderbox (Getaway Experience) 50eur"],
            ["50019", "Wonderbox (Wellness Experience) 50eur"],
            ["50020", "Wonderbox (Restaurant Experience) 50eur"],
            ["50021", "Wonderbox (Driving Experience) 50eur"]
        ];
        for (var i = 0; i < certs.length; i++) {
            if (parseInt(certs[i][0]) === id) {
                return certs[i][1];
            }
        }
    }

    saveReserv = () => {
        var n_this = this;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: '"' + this.state.selectedCert + ';' + this.state.newReserv + '"'
        };
        fetch("Admin/ChangeReserv", requestOptions).then(x => { n_this.fetchReservs(); n_this.setState({ modal: false }); toast('Готово.') });

    }

    closeModal = () => {
        this.setState({ modal: false });
    }

    toggleTable = (e) => {
        switch (e.target.name) {
            case 'poland':
                return this.setState({ polandTableShow: !this.state.polandTableShow });
            case 'germany':
                return this.setState({ germanyTableShow: !this.state.germanyTableShow });
            case 'spain':
                return this.setState({ spainTableShow: !this.state.spainTableShow });
        }
    }

    getInStock = (country) => {
        switch (country) {
            case 'poland':
                const polandStock = [];
                this.state.certs.filter(cert => cert.country === 616).forEach(x => polandStock.push(x.inStock));
                return polandStock.join(' / ');

            case 'germany':
                const germanyStock = [];
                this.state.certs.filter(cert => cert.country === 276).forEach(x => germanyStock.push(x.inStock));
                return germanyStock.join(' / ');
            case 'spain':
                const spainStock = [];
                this.state.certs.filter(cert => cert.country === 724).forEach(x => spainStock.push(x.inStock));
                return spainStock.join(' / ');
        }
    }

    render() {
        return (
            <div>
                <div style={{ display: this.state.modal ? 'block' : 'none' }} className="modal" >
                    <div className="modal-dialog modal-dialog-scrollable"  >
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" onClick={() => this.closeModal()} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <input onInput={(e) => this.setState({ newReserv: e.target.value })} />
                                <button onClick={() => this.saveReserv()} className="btn btn-secondary">Сохранить</button>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={() => this.closeModal()} className="btn btn-secondary" data-dismiss="modal">Закрыть</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
                <div>
                    <Button onClick={(e) => this.toggleTable(e)} style={{ width: '100%', marginBottom: '10px', textAlign: 'left' }} outline name='poland' color="primary">
                        Poland
                        <br />
                        {this.getInStock('poland')}
                    </Button>
                    {this.state.polandTableShow &&
                        <table className="table">
                            <tr>
                                <th>
                                    Id
                                </th>
                                <th>
                                    Сертификат
                                </th>
                                <th>
                                    Тип
                                </th>
                                <th>
                                    Резерв
                                </th>
                                <th>
                                    В наличии
                                </th>
                            </tr>
                            {this.state.certs.filter(cert => cert.country === 616).map(x =>
                                <tr key={x.id}>
                                    <td>{x.certType}</td>
                                    <td>{this.getCertName(x.certType)}</td>
                                    <td>{x.uploadType === 1 ? 'АПИ' : "Ручная загрузка"}</td>
                                    <td>{x.nReserve}</td>
                                    <td>{x.inStock}</td>
                                    <td><button onClick={() => this.setState({ modal: true, selectedCert: x.id })} className="btn btn-primary">Изменить</button></td>
                                </tr>
                            )}
                        </table>
                    }
                </div>
                <div>
                    <Button onClick={(e) => this.toggleTable(e)} style={{ width: '100%', marginBottom: '10px', textAlign: 'left' }} outline name='germany' color="primary">
                        Germany
                        <br />
                        {this.getInStock('germany')}
                    </Button>
                    {this.state.germanyTableShow &&
                        <table className="table">
                            <tr>
                                <th>
                                    Id
                                </th>
                                <th>
                                    Сертификат
                                </th>
                                <th>
                                    Тип
                                </th>
                                <th>
                                    Резерв
                                </th>
                                <th>
                                    В наличии
                                </th>
                            </tr>
                            {this.state.certs.filter(cert => cert.country === 276).map(x =>
                                <tr key={x.id}>
                                    <td>{x.certType}</td>
                                    <td>{this.getCertName(x.certType)}</td>
                                    <td>{x.uploadType === 1 ? 'АПИ' : "Ручная загрузка"}</td>
                                    <td>{x.nReserve}</td>
                                    <td>{x.inStock}</td>
                                    <td><button onClick={() => this.setState({ modal: true, selectedCert: x.id })} className="btn btn-primary">Изменить</button></td>
                                </tr>
                            )}
                        </table>
                    }
                </div>
                <div>
                    <Button onClick={(e) => this.toggleTable(e)} style={{ width: '100%', textAlign: 'left' }} outline name='spain' color="primary">
                        Spain
                        <br />
                        {this.getInStock('spain')}
                    </Button>
                    {this.state.spainTableShow &&
                        <table className="table">
                            <tr>
                                <th>
                                    Id
                                </th>
                                <th>
                                    Сертификат
                                </th>
                                <th>
                                    Тип
                                </th>
                                <th>
                                    Резерв
                                </th>
                                <th>
                                    В наличии
                                </th>
                            </tr>
                            {this.state.certs.filter(cert => cert.country === 724).map(x =>
                                <tr key={x.id}>
                                    <td>{x.certType}</td>
                                    <td>{this.getCertName(x.certType)}</td>
                                    <td>{x.uploadType === 1 ? 'АПИ' : "Ручная загрузка"}</td>
                                    <td>{x.nReserve}</td>
                                    <td>{x.inStock}</td>
                                    <td><button onClick={() => this.setState({ modal: true, selectedCert: x.id })} className="btn btn-primary">Изменить</button></td>
                                </tr>
                            )}
                        </table>
                    }
                </div>
            </div>
        )
    }
}
