import React, { Component } from 'react';
import { Route } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
require('bootstrap')

class Dashboard extends Component {
    constructor() {
        super();
        this.state = {
            projects: [],
            from: '',
            to: '',
            data: [],
            fromFilter: '',
            c_from: '',
            c_to: '',
            toFilter: ''
        };
    }

    componentDidMount = () => {
        var n_this = this;
        fetch('Admin/GetProjects?Id=1').then(x => x.json()).then(x => this.setState({ projects: x }))
        fetch('Admin/GetNormalDate').then(x => x.text()).then(x => {
            fetch('Admin/GetStatistic?from=' + x.split(';')[0] + '&to=' + x.split(';')[1]).then(x => x.json()).then(x => this.setState({ data: x }));
            this.setState({ from: x.split(';')[0], to: x.split(';')[1], fromFilter: x.split(';')[0], toFilter: x.split(';')[1] })
        });

    }

    getDetail = () => {
       
        window.open('Api/DetailStatistic?from=' + this.state.from + '&to=' + this.state.to, '_blank');
        /*  var xhr = new XMLHttpRequest();
          xhr.open('GET', 'Admin/DetailStatistic?from=' + this.state.from + '&to=' + this.state.to);
          xhr.send();*/
    }
    getReportCert = () => {
        fetch('Admin/GetStatFile?dt1=' + this.state.c_from + "&dt2=" + this.state.c_to, { method: "POST", headers: { 'Content-Type': 'application/json' } }).then(x => alert(x.status === 200 ? 'Відправлено' : 'Помилка сервера'));
    }
    filter = () => {
        fetch('Admin/GetStatistic?from=' + this.state.fromFilter + '&to=' + this.state.toFilter).then(x => x.json()).then(x => this.setState({ data: x }));
    }

    render() {
        let first = true;
        return (
            <div className="">
                <ToastContainer />
                <div style={{ display: 'flex' }}>
                    <div className="card" style={{ display: 'flex', alignItems: 'baseline', width: '300px' }}>
                        <div className="card-body">
                            <h5 className="card-title">Скачать подробный отчет</h5>
                            <p style={{ marginRight: '5px' }}>Дата с</p>
                            <input defaultValue={this.state.from} onInput={(e) => this.setState({ from: e.target.value })} style={{ marginRight: '5px' }} className="form-controll" type="date" />
                            <p style={{ marginRight: '5px' }}> по </p>
                            <input defaultValue={this.state.to} onInput={(e) => this.setState({ to: e.target.value })} className="form-controll" type="date" />
                            <button Style="color: white !important;" onClick={this.getDetail} className="btn btn-primary">Применить</button>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <p className="card-title">Фильтр</p>
                            <p style={{ marginRight: '5px' }}>Дата с</p>
                            <input defaultValue={this.state.fromFilter} onInput={(e) => this.setState({ fromFilter: e.target.value })} style={{ marginRight: '5px' }} className="form-controll" type="date" />
                            <p style={{ marginRight: '5px' }}> по </p>
                            <input defaultValue={this.state.toFilter} onInput={(e) => this.setState({ toFilter: e.target.value })} className="form-controll" type="date" />
                            <button Style="color: white !important;" onClick={this.filter} className="btn btn-primary">Применить</button>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <p className="card-title">Звіт по сертифікатам</p>
                            <p style={{ marginRight: '5px' }}>Дата с</p>
                            <input defaultValue={this.state.c_from} onInput={(e) => this.setState({ c_from: e.target.value })} style={{ marginRight: '5px' }} className="form-controll" type="date" />
                            <p style={{ marginRight: '5px' }}> по </p>
                            <input defaultValue={this.state.c_to} onInput={(e) => this.setState({ c_to: e.target.value })} className="form-controll" type="date" />
                            <button Style="color: white !important;" onClick={() => this.getReportCert()} className="btn btn-primary">Применить</button>
                        </div>
                    </div>
                </div>
                

                <table className="table">
                    {this.state.data.map((x) => {
                        if (first) {
                            first = false;
                            return <tr>
                                {x.map(y =>
                                    <th>{y}</th>
                                )}
                            </tr>;

                        } else {
                            return <tr>
                                {x.map(y =>
                                    <td>{y}</td>
                                )}
                            </tr>;

                        }
                    }
                    )}

                </table>
                <table style={{ display: 'none' }} className="table">
                    <tr>
                        <th>Проект/сертификат</th>
                        {this.state.projects.map(x =>
                            <th>
                                {x.split(';')[1]}
                                <br />
                                (заказали/вывели)
                            </th>
                        )}
                    </tr>
                    <tr>
                        <td>
                            Сильпо 500
                        </td>
                        <td>
                            60/10
                        </td>3
                    </tr>
                    <tr>
                        <td>
                            Комфи 500
                        </td>
                        <td>
                            80/20
                        </td>
                    </tr>
                </table>


            </div>
        );
    }

}
export default Dashboard;


