import React, { Component } from 'react';
import { Route } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import _load from './load.gif';
require('bootstrap')

class Upload extends Component {
    constructor() {
        super();
        this.state = {
            modalState: false,
            dopCode: 0,
            data: [],
            preview: [],
            certs: [],
            loading: false,
            project: -1,
            certificate: -1,
            projects: []
        };
    }
    componentDidMount = () => {
        fetch('Admin/GetManualCerts').then(x => x.json()).then(x => this.setState({ certs: x }));
        fetch('Admin/GetProjects?Id=1').then(x => x.json()).then(x => this.setState({ projects: x }));
    }
    readFile = (evt) => {

        var noThis = this;
        var f = evt.target.files[0];
        var arr = [];
        var reader = new FileReader();
        reader.onloadstart = function () {
        };
        reader.onload = function () {

            var arrayBuffer = reader.result;
            var array = new Uint8Array(arrayBuffer);

            var data = "";

            array.forEach(function (e) {
                data += e + ';'
            });

            noThis.setState({ data: data });
            fetch('Admin/PreviewUpload', {
                method: "POST",
                body: `"${data}"`,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then(data => data.json()).then(x => noThis.setState({
                preview: x, loading: false, modalState: true
                 }))

        };
        if (f) {

            reader.readAsArrayBuffer(evt.target.files[0]);
            this.setState({ loading: true });
        }
        else {

        }

    }
    closeModal = () => {
        this.setState({ modalState: false });
    }
    send = () => {
        if (this.state.data === []) {
            toast('Залейте файл');
            return;
        } else if (this.state.certificate === -1) {
            toast('Выберете сертификат');
            return;
        }
        else if (this.state.project === -1) {
            toast('Выберете проект');
            return;
        }
        var n_this = this;
        fetch('Admin/UploadCertificates?CertId=' + this.state.certificate + '&dopCode=' + this.state.dopCode + '&project=' + this.state.project, {
            method: "POST",
            body: `"${this.state.data}"`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(x => {
            if (x.status === 200) {
                toast('Готово');
            } else {
                toast('Ошибка сервера');
            }
            });


    }
    render() {
        return (
            <div className="">
                <ToastContainer />
                
                <div style={{ position: 'fixed', top: 'calc(50vh - (494px / 2))', left: 'calc(50vw - (494px / 2))', display: this.state.loading ? '' : 'none' }}>
                    <img src={_load}/>
                </div>
                <button style={{ display: 'none' }} onClick={() => this.setState({ modalState: this.state.modalState ? false : true })}></button>
                <div>
                    <p>Сертификат</p>
                    <select onInput={(e) => this.setState({ certificate: e.target.value })} class="form-control">
                        <option></option>
                        {this.state.certs.map(x =>
                            <option value={x.split(';')[0]}>{x.split(';')[1]}</option>)}
                    </select>
                </div>
                <div>
                    <p>Проект</p>
                    <select onInput={(e) => this.setState({ project: e.target.value })} class="form-control">
                        <option></option>
                        {this.state.projects.map(x =>
                            <option value={x.split(';')[0]}>{x.split(';')[1]}</option>
                            )}
                    </select>
                </div>
                <div>
                    <p>Дополнительный код</p>
                    <select onInput={(e) => this.setState({ dopCode: e.target.value })} class="form-control">
                        <option value={0}>Нет</option>
                        <option value={1}>PIN</option>
                        <option value={2}>Id картки</option>
                    </select>
                </div>
                <div>
                    <p>Файл</p>
                    <input onChange={this.readFile} type="file"/>
                </div>
                <br />
                <div>
                    <button Style="color: white !important;" className="btn btn-primary" onClick={this.send}>Отправить</button>
                </div>
                <div style={{ display: this.state.modalState ? 'block': 'none' }} className="modal" >
                    <div className="modal-dialog modal-dialog-scrollable" >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Превью</h5>
                                <button type="button" onClick={this.closeModal}  className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <table className="table">
                                    <tr>
                                        <th>
                                            Код
                                        </th>
                                        <th>
                                            Срок действия
                                        </th>
                                        {this.state.dopCode !== 0 ?
                                            <th>
                                                Дополнительный код
                                            </th>
                                            : <></>}
                                    </tr>
                                    {this.state.preview.map(x =>
                                        <tr>
                                            <td>
                                                {x.split(';')[0]}
                                            </td>
                                            <td>
                                                {x.split(';')[1]}
                                            </td>
                                            {this.state.dopCode !== 0 ? x.split(';')[2]  : <></>}
                                        </tr>
                                        )}
                                    
                                </table>
                            </div>
                            <div className="modal-footer">
                               
                                <button onClick={this.closeModal} type="button" className="btn btn-secondary" data-dismiss="modal">Закрыть</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default Upload;


