import React, { Component } from 'react';
import { Route } from 'react-router';
require('bootstrap')

class PhysicalCerts extends Component {
    constructor(props) {
        super();
        this.certs = [
            ["20001", "Гудвайн"],
            ["20002", "Брокард"],
            ["20003", "Ла Фамілія"],
            ["20004", "Ла Фамілія 1000"],
            ["20005", "Synergy Group 1000"],
            ["-1001", "TEST 0"],
            ["-1002", "TEST 2"],
            ["-1003", "TEST MANY"]
        ];
        this.state = {
            modal: false,
            search_sended: [],
            origin_sended: [
                
            ],
            certsAvaible: [],
            toAdded: 0,
            addCerts: 0,
            search: ''
        };
    }
    getCertName = (id) => {
        var certs = this.certs;
        for (var i = 0; i < certs.length; i++) {
            if ((certs[i][0]) === id) {
                return certs[i][1];
            }
        }
        
    }
    componentDidMount = ()=>{
      
        this.getReserv();
        this.getHistory();
    }
    getReserv = () => {
        fetch('Admin/GetPhysicalReserv').then(x => x.json()).then(x => this.setState({ certsAvaible: x }));
    }
    closeModal = () => {
        this.setState({ modal: false });
    }
    search = (value) => {
        if (value.length > 0){
            var res = this.state.origin_sended.filter(x=> x.userId.toString().indexOf(value.toString()) !== -1);
            this.setState({search_sended: res});
        }
        else{
            this.setState({search_sended: this.state.origin_sended});
        }
        this.setState({ search: value });
    }
    toAddCerts = (e) => {
        if (/^\d+$/.test(e) || e.length === 0) {
            this.setState({ addCerts: e });
        }

    }
    getHistory = () => {
        fetch('Admin/GetPhysicalHistory', { method: 'POST' }).then(x => x.json()).then(x => this.setState({ origin_sended: x, search_sended: x }));
    }
    addCertFetch = () => {
        
        if (this.state.addCerts > 0) {
            var json = {
                certId: parseInt(this.state.toAdded),
                count: parseInt(this.state.addCerts)
            };
            fetch('Admin/SetPhysicalCerts', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(json)
            }).then(x => { this.closeModal(); this.getReserv(); });
        }
    }
    render() {
        return (
            <div style={{ padding: '16px' }}>
                <div style={{ display: this.state.modal ? 'block' : 'none' }} className="modal" >
                    <div className="modal-dialog modal-dialog-scrollable"  >
                        <div className="modal-content">
                            <div className="modal-header">


                            </div>
                            <div className="modal-body">
                                <input type={"text"} value={this.state.addCerts} onInput={(e) => this.toAddCerts(e.target.value)} />
                                <button type="button" onClick={() => this.addCertFetch()} className="btn btn-secondary">Додати</button>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={() => this.closeModal()} className="btn btn-secondary" data-dismiss="modal">Закрыть</button>
                            </div>
                        </div>
                    </div>
                </div>
                <h3>Управління фізичними сертифікатами</h3>
                <br />
                <table style={{ textAlign: 'center' }} className="table">
                    <tr>
                        <th>Id</th>
                        <th>Назва</th>
                        <th>Доступна кількість</th>
                        <th>Резерв</th>
                        <th>Доступна по таксеру</th>
                        <th></th>
                    </tr>
                    {this.state.certsAvaible.map(x =>
                        <tr>
                            <td>{x.certId}</td>
                            <td>{this.getCertName(x.certId)}</td>
                            <td>{x.inStock}</td>
                            <td>{x.nReverv}</td>
                            <td>{x.taxed}</td>
                            <td><button onClick={() => this.setState({ modal: true, toAdded: x.certId })} className="btn btn-primary">Додати сертифікати</button></td>
                        </tr>)}
                </table>
                <br />
                <br />
                <br />

                <h3>Історія</h3>
                <div>
                    <p>Пошук</p>
                    <input onInput={(e) => this.search(e.target.value)} type="text" value={this.state.search} />
                </div>
                <table style={{ textAlign: 'center' }} className="table">
                    <tr>
                        <th>
                            Тип
                        </th>
                        <th>
                            Дата
                        </th>
                        <th>
                            UserId
                        </th>
                    </tr>
                    {this.state.search_sended.map(x =>
                        <tr key={x.id}>
                            <td>{this.getCertName(x.type.toString())}</td>
                            <td>{x.usedTime === null ? "" : x.usedTime.replace('T', ' ')}</td>
                            <td>{x.userId}</td>
                        </tr>)}
                </table>
            </div>
        );
    }
}
export default PhysicalCerts;
