import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Login from './components/Login.js';
import Main from './Main.js';

import './App.css';
require('bootstrap')

class App extends Component {
  constructor() {
      super();
      this.state = {
          logined: false
      };
  }

  componentDidMount = () => {
    //  toast(window.location.href);
  }

  login = () => {
      this.setState({ logined: true });
  }
  getRightBlock = () => {
      if (window.location.href.indexOf('DetailStatistic') !== -1) {
         // return;
      } else {
        //return <Main/>;
          return this.state.logined ? <Main /> : <Login login={this.login} />;
      }
  }
  render() {
      return (
          <div>
              <ToastContainer />
              {this.getRightBlock()}
          </div>
      );
  }

}
export default App;
