import React, { Component } from 'react';
import { Route } from 'react-router';
import Sidebar from './components/Sidebar.js';
import ProjectSetup from './components/ProjectSetup.js'; 
import Login from './components/Login.js';
import Dashboard from './components/Dashboard.js';
import Reserv from './components/Reservs.js';
import Admin from './components/Admin.js';
import EuropeanCerts from './components/EuropeanCerts.js';
import Emails from './components/Emails.js';
import Upload from './components/Upload.js';
import PhysicalCerts from './components/PhysicalCerts.js';
import ApiLogs from './components/ApiLogs.js';
import Infos from './components/Infos.js';
import Queues from './components/Queues.js'
import './App.css';
require('bootstrap')

class Main extends Component {
    constructor() {
        super();
        this.state = {
            data: '',
            preview: [],
            loading: false,
            modalStatus: false,
            balance: -1,
            frameLoad: 0
        };
    }

    componentDidMount = () => {
        fetch('Front/GetBalance').
            then(x => x.json()).then(x => this.setState({ balance: x.balance }));
    }
    readFile = (evt) => {

        var noThis = this;
        var f = evt.target.files[0];
        var arr = [];
        var reader = new FileReader();
        reader.onloadstart = function () {
        };
        reader.onload = function () {

            var arrayBuffer = reader.result;
            var array = new Uint8Array(arrayBuffer);

            var data = "";

            array.forEach(function (e) {
                data += e + ';'
            });

            noThis.setState({ data: data });
            fetch('Front/UploadPreview', {
                method: "POST",
                body: `"${data}"`,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then(data => data.json()).then(x => noThis.setState({ preview: x, loading: false, modalStatus: true }))

        };
        if (f) {

            reader.readAsArrayBuffer(evt.target.files[0]);
            this.setState({ loading: true });
        }
        else {

        }

    }
    getSecondBlock = (frameLoad) => {
        if (frameLoad == 0) {
            return <Dashboard />;
        } else if (frameLoad == 1) {
            return <Upload />;
        } else if (frameLoad == 2) {
            return <ProjectSetup />;
        }
        else if (frameLoad == 3) {
            return <Admin />;
        }
        else if (frameLoad == 4) {
            return <Reserv />;
        }
        else if (frameLoad == 5) {
            return <Emails />;
        }
        else if (frameLoad == 6) {
            return <ApiLogs />;
        }
        else if (frameLoad == 7) {
            return <Infos />;
        } else if (frameLoad == 8) {
            return <Queues />
        }
        else if (frameLoad == 10){
            return <PhysicalCerts/>
        }
        else if (frameLoad == 11) {
            return <EuropeanCerts />
        }
        else {
            return <></>;
        }
    }
    changeFrameLoad = (i) => {
        this.setState({ frameLoad: i });
    }
    render() {
        return (
            <div>
                <Sidebar changeFrameLoad={this.changeFrameLoad} />
                <div style={{ marginLeft: '20%' }}>
                    {this.getSecondBlock(this.state.frameLoad)}
                </div>
            </div>
        );
    }

}
export default Main;

/*
 
 */

/*


                <button className="btn">maska</button>
                <div>
                    <p>Файл до <span style={{ color: "red" }}>7 Мбайт</span></p>
                    <input onChange={this.readFile} type="file" />
                </div>
                <div>
                    <p>Баланс: {balance}</p>
                </div>
                <ul>
                    {preview.map(x =>
                        <li>{x}</li>)}
                </ul>
 */